/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

$(function () {
    update_breadcrumb();
    update_product_list_select();
    init_wishlist();
    init_selectpicker();
    init_quantity_control();

    $(window).resize(function() {
        update_breadcrumb();
    });

    if ($('#slider-slider-desktop').length) {
        var sliderSliderDesktop = tns({
            container: '#slider-slider-desktop',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: true,
            mouseDrag: true,
            nav: false,
            controls: false,
            autoplayTimeout: 10000,
            speed: 500,
            onInit: function (info) {
                $('.slider-slider-desktop .slider-nav-counter .total').text(info.slideCount);
            }
        });

        sliderSliderDesktop.events.on('indexChanged', function (info) {
            $('.slider-slider-desktop .slider-nav .current').text(info.displayIndex);
        });

        $('.slider-slider-desktop .slider-arrow-btn.prev').click(function (e) {
            e.preventDefault();
            sliderSliderDesktop.pause();
            sliderSliderDesktop.goTo('prev');
            sliderSliderDesktop.play();
        });

        $('.slider-slider-desktop .slider-arrow-btn.next').click(function (e) {
            e.preventDefault();
            sliderSliderDesktop.pause();
            sliderSliderDesktop.goTo('next');
            sliderSliderDesktop.play();
        });
    }

    if ($('#slider-slider-mobile').length) {
        var sliderSliderMobile = tns({
            container: '#slider-slider-mobile',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: true,
            mouseDrag: true,
            nav: false,
            controls: false,
            autoplayTimeout: 10000,
            speed: 500,
            onInit: function (info) {
                $('.slider-slider-mobile .slider-nav-counter .total').text(info.slideCount);
            }
        });

        sliderSliderMobile.events.on('indexChanged', function (info) {
            $('.slider-slider-mobile .slider-nav .current').text(info.displayIndex);
        });


        $('.slider-slider-mobile .slider-arrow-btn.prev').click(function (e) {
            e.preventDefault();
            sliderSliderMobile.pause();
            sliderSliderMobile.goTo('prev');
            sliderSliderMobile.play();
        });

        $('.slider-slider-mobile .slider-arrow-btn.next').click(function (e) {
            e.preventDefault();
            sliderSliderMobile.pause();
            sliderSliderMobile.goTo('next');
            sliderSliderMobile.play();
        });
    }

    $('.js-toggle-main-menu').click(function (e) {
        e.preventDefault();

        $('.main-menu').toggleClass('active');
        setTimeout(function () {
            $('.submenu').removeClass('active');
        }, 400);
    });

    if (get_vw() > screen_lg) {
        var menuDropdownTimer;

        $('.menu.main > li.dropdown').hover(function () {
            let $this = $(this);
            menuDropdownTimer = setTimeout(function () {
                $this.addClass('active');
                $('.header-overlay').addClass('active');
            }, 50);
        }, function () {
            clearTimeout(menuDropdownTimer);
            $(this).removeClass('active');
            $('.header-overlay').removeClass('active');
        });
    } else {
        $('.js-toggle-dropdown').click(function (e) {
            e.preventDefault();
            $(this).closest('.dropdown').find('> .submenu').toggleClass('active');
        });
    }

    $('.js-languages-modal, .languages-modal-bg').click(function (e) {
        e.preventDefault();
        $('[data-box-modal]').not('.languages-modal').removeClass('active');
        $('[data-call-box-modal]').not(this).removeClass('active');
        $('.languages-modal').toggleClass('active');
        $(this).toggleClass('active');
    });

    $('.js-profile-modal').click(function (e) {
        e.preventDefault();
        $('[data-box-modal]').not('.profile-menu-modal').removeClass('active');
        $('[data-call-box-modal]').not(this).removeClass('active');
        $('.profile-menu-modal').toggleClass('active');
        $(this).toggleClass('active');
    });

    if (get_vw() >= screen_lg) {
        $('.main-menu .li1.floating').hover(function () {
            let mainSubmenu = $(this).find('.main-submenu');
            let mainSubmenuWidth = Math.round(mainSubmenu.outerWidth());
            let left = Math.round($(this).offset().left);
            let space = Math.round(get_vw() - left);

            if (space < mainSubmenuWidth && (left + $(this).outerWidth()) < mainSubmenuWidth) {
                mainSubmenu.css('transform', 'translateX(-' + Math.round(mainSubmenuWidth - space) + 'px)');
            } else if (space < mainSubmenuWidth) {
                mainSubmenu.addClass('right');
            }
        });
    }

    $('[data-submenu-target]').hover(function () {
        let target = $(this).data('submenu-target');
        let parent = $(this).closest('.main-submenu');
        parent.find('.drop-submenu').addClass('d-none');
        parent.find('[data-submenu-target]').removeClass('active');
        $(target).removeClass('d-none');
        $(this).addClass('active');
    });

    $('.js-toggle-quick-search').click(function (e) {
        e.preventDefault();
        let quickSearch = $('#quick-search');

        quickSearch.toggleClass('active');

        if (quickSearch.hasClass('active')) {
            quickSearch.find('input[name="search"]').val('');
            quickSearch.find('#search-suggestions-block').empty();
            quickSearch.removeClass('has-content');

            setTimeout(function () {
                quickSearch.find('input[name="search"]').focus();
            }, 100);
        }
    });

    $(document).on('click', '.size-tables', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/products/size_tables',
            callback: function(html) {
                doModal(html, 'size-tables-modal');
            }
        });
    });

    $(document).on('click', '.js-toggle-cart-info', function(e) {
        e.preventDefault();
        let cartInfoWRP = $('#cart-info');

        cartInfoWRP.find('#cart-info-details').toggleClass('active');
        cartInfoWRP.find('.cart-info').toggleClass('active');
    });

    // Slaptazodzio input type password/text keitimas
    $(document).on('click', '.show-psw', function(e) {
        e.preventDefault();

        let parent = $(this).closest('.show-psw-wrp');
        let input = parent.find('input');

        if (input.attr('type') === 'password') {
            $(this).addClass('show')
            input.attr('type', 'text');
        } else {
            $(this).removeClass('show')
            input.attr('type', 'password');
        }
    });

    $(document).on('click', '.need2prelogin', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/prelogin',
            callback: function(html) {
                doModal(html, 'modal-auth modal-prelogin');
            }
        });
    });
    $(document).on('click', '.need2authorize', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/authorize',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });
    $(document).on('click', '.need-2-login', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/login',
            callback: function(html) {
                doModal(html, 'modal-auth modal-login');
            }
        });
    });
    $(document).on('click', '.need2register', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/register',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });
    $(document).on('click', '.need2remind_password', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/remind_password',
            callback: function(html) {
                doModal(html, 'modal-auth');
            }
        });
    });

    $(document).on('click', '.content-accordion .content-accordion-header', function(e) {
        e.stopImmediatePropagation();

        $(this).toggleClass('collapsed');
        $(this).next().slideToggle(350);
    });

    if (get_vw() < screen_md) {
        $(document).on('click', '.filter-wrp .filter-title', function() {
            if($(this).attr('aria-expanded') == 'true'){
                $(this).attr('aria-expanded', 'false');
            }else{
                $(this).attr('aria-expanded', 'true');
            }
        });
    }
});